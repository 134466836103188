<template>
	<div class="site-wrapper" :class="{ 'site-sidebar--fold': sidebarFold }" v-loading.fullscreen.lock="loading"
		element-loading-text="拼命加载中">
		<template v-if="!loading">
			<main-navbar />
			<main-sidebar />
			<div class="site-content__wrapper" :style="{ 'min-height': documentClientHeight + 'px' }">
				<main-content v-if="!$store.state.common.contentIsNeedRefresh" />
			</div>
		</template>
	</div>
</template>

<script>
	import MainNavbar from './main-navbar'
	import MainSidebar from './main-sidebar'
	import MainContent from './main-content'
	export default {
		provide() {
			return {
				// 刷新
				refresh() {
					this.$store.commit('common/updateContentIsNeedRefresh', true)
					this.$nextTick(() => {
						this.$store.commit('common/updateContentIsNeedRefresh', false)
					})
				}
			}
		},
		data() {
			return {
				loading: false
			}
		},
		components: {
			MainNavbar,
			MainSidebar,
			MainContent
		},
		computed: {
			documentClientHeight: {
				get() {
					return this.$store.state.common.documentClientHeight
				},
				set(val) {
					this.$store.commit('common/updateDocumentClientHeight', val)
				}
			},
			sidebarFold: {
				get() {
					return this.$store.state.common.sidebarFold
				}
			},
			userId: {
				get() {
					return this.$store.state.user.id
				},
				set(val) {
					this.$store.commit('user/updateId', val)
				}
			},
			userName: {
				get() {
					return this.$store.state.user.name
				},
				set(val) {
					this.$store.commit('user/updateName', val)
				}
			}
		},
		created() {
			this.getUserInfo()
		},
		mounted() {
			this.resetDocumentClientHeight()
		},
		methods: {
			// 重置窗口可视高度
			resetDocumentClientHeight() {
				this.documentClientHeight = document.documentElement['clientHeight']
				window.onresize = () => {
					this.documentClientHeight = document.documentElement['clientHeight']
				}
			},
			// 获取登录人信息
			async getUserInfo() {
				let res = await this.$get('/platform-admin/user/info');
				if (res && res.code == 0) {
					this.loading = false
					this.userId = res.data.id
					this.userName = res.data.username
				}
			},
		}
	}
</script>
<style lang="scss">
	.el-button--mini {
		padding: 7px !important;
	}
</style>
